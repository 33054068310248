<template>
  <v-container fluid>
    <div class="upper-part">
      <div class="tableHeader">
        <p>{{ $vuetify.lang.t("$vuetify.users") }}</p>
        <div>
          <v-btn class="secondary" icon @click="dialog = true">
            <v-icon color="#fff">mdi-plus</v-icon>
          </v-btn>

          <v-btn
            v-if="selectedUsers.length"
            class="ml-5"
            color="error"
            outlined
            @click="deleteMultipleUsers"
            >Delete Selected Users</v-btn
          >
        </div>
      </div>

      <v-form
        class="tableSearch"
        style="width: 100%; display: flex"
        @submit.prevent="searchData"
      >
        <v-row align="center">
          <v-col cols="5">
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.search')"
              v-model="search"
              class="mx-2"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-select
              :items="searchable_items"
              :item-text="'text'"
              :item-value="'search'"
              v-model="search_field"
              :label="$vuetify.lang.t('$vuetify.search_from_column')"
              class="mx-2"
              disable-initial-sort
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-btn
              type="submit"
              block
              outlined
              color="secondary"
              class="caption ma-0"
              >{{ $vuetify.lang.t("$vuetify.search") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-data-table
      :headers="tableHeader"
      :items="formatedUserList"
      :loading="loading"
      :options="paginationOptions"
      :server-items-length="totalCount"
      @pagination="someChange"
      v-model="selectedUsers"
      show-select
      item-key="id"
      height="530"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1"
    >
      <template v-slot:[`item.role`]="{ item }">
        {{ item.role | roleFilter }}
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <v-btn icon class="secondary mr-3">
          <v-icon color="#fff" @click="editUser(item.id)">mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon class="error">
          <v-icon color="#fff" @click="deleteSingleUser(item.id)"
            >mdi-delete</v-icon
          >
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" maxWidth="600px">
      <AddUser
        :key="dialog"
        :edit="edit"
        :employee_id="employee_id"
        @close-model="closeModal"
        @refresh="fetchUsers"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import AddUser from "@/components/add-user/add-user";
import { mapState } from "vuex";
export default {
  name: "setting-users",
  components: { AddUser },
  data() {
    return {
      loading: false,
      users: [],
      selectedUsers: [],
      totalCount: 0,
      currentPage: 1,
      limit: 10,
      // ----
      search_field: "first_name",
      search: "",
      // add-user
      dialog: false,
      edit: false,
      employee_id: "",
    };
  },
  computed: {
    ...mapState(["user"]),
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    tableHeader() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.first_name"),
          align: "start",
          value: "first_name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.last_name"),
          align: "start",
          value: "last_name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email"),
          align: "start",
          value: "email",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.role"),
          value: "role",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action"),
          value: "id",
        },
      ];
    },
    searchable_items() {
      return [
        {
          search: "first_name",
          text: "First Name",
        },
        {
          search: "last_name",
          text: "Last Name",
        },
        {
          search: "email",
          text: "Email",
        },
      ];
    },
    like() {
      if (this.search) {
        const like = {
          key: this.search_field,
          value: this.search,
        };
        return JSON.stringify(like);
      }
      return false;
    },
    formatedUserList() {
      return this.users.map((user) => {
        const { _id, first_name, last_name, email, role } = user;

        return {
          id: _id,
          first_name,
          last_name,
          email,
          role,
        };
      });
    },
  },
  methods: {
    async fetchUsers() {
      try {
        this.loading = true;
        const { currentPage, limit } = this;

        let url = `/user?p=${currentPage}&limit=${limit}&desc=created_at&company_id=${this.user.company_id}&w=company`;

        if (this.like) {
          url = `/user?p=${currentPage}&limit=${limit}&desc=created_at&company_id=${this.user.company_id}&w=company&like=${this.like}`;
        }

        const resp = await this.axios.get(url);
        const { data, count } = resp.data;
        this.users = data;
        this.totalCount = count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    someChange(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchUsers();
      }
    },
    searchData() {
      this.currentPage = 1;
      this.fetchUsers();
    },
    closeModal() {
      this.dialog = false;
      (this.edit = false), (this.employee_id = "");
    },
    editUser(id) {
      this.edit = true;
      this.employee_id = id;
      this.dialog = true;
    },
    deleteUser(id) {
      return new Promise((resolve, reject) => {
        this.axios
          .delete(`/user/${id}`)
          .then(() => {
            resolve(id);
          })
          .catch((err) => reject(err));
      });
    },
    async deleteSingleUser(id) {
      try {
        this.loading = true;
        await this.deleteUser(id);
        const index = this.users.findIndex((user) => user._id === id);
        if (index !== -1) {
          this.users.splice(index, 1);
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteMultipleUsers() {
      if (this.selectedUsers.length) {
        try {
          this.loading = true;
          await Promise.all(
            this.selectedUsers.map(({ id }) => this.deleteUser(id))
          );
          this.selectedUsers = [];
          this.fetchUsers();
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
  padding: 1rem;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
